.navbar {
    position: fixed;
    top: 0;
    right: 0;
    padding-top: 20px;
}

.navbar span {
    margin-left: -10px;
    padding-right: 20px;
    cursor: pointer;
    position: relative;
    display: inline-block;
}

.navbar .link-text {
    text-decoration: none;
    font-weight: bold;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
    color: white;
    font-size: 16px;
    position: relative;
    display: inline;
}

.navbar .link-text::after {
    content: '';
    position: absolute;
    width: 80%;
    height: 4px;
    background-color: white;
    bottom: -10px;
    left: -1px;
    opacity: 0;
    transition: opacity 0.3s;
}

.navbar span:hover .link-text::after,
.navbar span.active .link-text::after {
    opacity: 1;
}

.navbar span:hover .link-text::after {
    opacity: 0.3;
}
