.video-responsive {
    width: 80%; /* Width of the video */
    position: relative;
    padding-top: 45%; /* Aspect ratio of 16:9 */
    border: 10px solid rgba(14, 14, 14, 0.8);
    border-radius: 5px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;
}

.video-responsive iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
