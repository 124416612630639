body {
    overflow-x: hidden;
    overflow-y: scroll;
    background-color: black;
}

.App {
    background-image: url("../public/splash.jpg");
    background-size: max(100%, 1920px) auto;
    background-position: top;
    background-repeat: no-repeat;
    text-align: center;
}

.App-header {
    display: flex;
    justify-content: center;
    /* align-items: center; */
    min-height: 100vh;
}

.App-header-home {
    display: flex;
    gap: 20px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
}

.home-content {
    margin-top: 200px;
    display: block;
    width: 55%;
}

@media (min-width: 601px) {
    .home-content {
        width: 35%;
    }
}

.App-header-faq {
    display: flex;
    justify-content: center;
    min-height: 100vh;
}

.App-link {
    color: #61dafb;
}
