.discord-logo {
    width: 50%;
    margin: 0 auto;
    margin-bottom: 70px;
}

@media (min-width: 1301px) {
    .discord-logo {
        width: 40%;
    }
}

.discord-logo img {
    width: 100%;
}
