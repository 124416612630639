.socials {
    width: 80%;
    background-color: rgba(14, 14, 14, 0.8);
    margin-top: 70px;
    margin-bottom: 70px;
    border-radius: 5px;
    padding: 15px;
}

.banner img {
    max-width: 100%;
    height: auto;
}

.profile-and-icons {
    display: flex;
    flex-wrap: wrap; /* Allows items to wrap onto the next line */
    justify-content: center;
}

.profile {
    flex: 2; /* Takes more space */
}

.icons {
    display: flex; /* Enables flexbox */
    flex: 1;
    gap: 10px;
    align-items: center; /* Centers items vertically */
}

.icon {
    flex: 1;
}

@media (min-width: 601px) {
    .socials {
        max-width: 60%;
    }

    .profile {
        max-width: 30%;
    }
}

@media (max-width: 600px) {
    .profile-and-icons {
        flex-direction: column;
    }

    .icons {
        flex-direction: row;
        flex-wrap: wrap;
    }

    .icon {
        flex-basis: 30%;
    }
}
