.press-release {
    width: 80%;
    background-color: rgba(14, 14, 14, 0.8);
    margin-top: 70px;
    padding: 20px;
    margin-bottom: 70px;
    border-radius: 5px;
    color: white;
}

.press-release a {
    color: #e6f542;
}

.press-release a:visited {
    color: #e6f542;
}

.press-release img {
    max-width: 100%;
}

.press-release h1 {
    font-size: 2.25rem;
    font-weight: 600;
}

.press-release h2 {
    font-size: 2.25rem;
    font-weight: 600;
    color: red;
}

.press-release h4 {
    font-size: 1.25rem;
    font-weight: 300;
}

@media (min-width: 601px) {
    .press-release {
        max-width: 60%;
    }
}
