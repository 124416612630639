.faq {
    width: 80%;
    background-color: rgba(14, 14, 14, 0.8);
    margin-top: 70px;
    padding-left: 20px;
    padding-right: 20px;
    margin-bottom: 70px;
    border-radius: 5px;
    color: white;
}

.faq a {
    color: #e6f542;
}

.faq a:visited {
    color: #e6f542;
}

.faq img {
    max-width: 100%;
}

.faq h1 {
    font-size: 2.25rem;
    font-weight: 600;
}

.faq h4 {
    font-size: 1.25rem;
    font-weight: 300;
}

@media (min-width: 601px) {
    .faq {
        max-width: 60%;
    }
}
