.steam-wishlist {
    margin-bottom: 20px;
}

@media (min-width: 601px) {
    .steam-wishlist {
        margin-left: auto;
        margin-right: auto;
    }
}

.steam-wishlist iframe {
    width: 100%;
}
