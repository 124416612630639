.report-bug-page {
    width: 80%;
    background-color: rgba(14, 14, 14, 0.8);
    margin-top: 70px;
    padding: 20px;
    margin-bottom: 70px;
    border-radius: 5px;
    color: white;
}
.report-bug-page h1 {
    font-size: 2.25rem;
    font-weight: 600;
}

.report-bug-form {
	margin: 40px auto;
	max-width: 500px;
}

.report-bug-form .form-field {
	display: block;
	margin: 20px 0;
}

.report-bug-form label {
	display: block;
	text-align: left;
	margin: 5px 0;
	color: #bbb;
}

.report-bug-form input,
.report-bug-form select,
.report-bug-form textarea {
	display: block;
	padding: 10px;
	background: #000;
	color: #fff;
	border: 1px solid #555;
	box-sizing: border-box;
	width: 100%;
	outline: none;
}

.report-bug-form textarea {
	min-height: 150px;
}

.report-bug-form button {
	background: #b48a5f;
	color: #fff;
	padding: 10px;
	border: 0;
	font-weight: bold;
}

.report-bug-form button:disabled {
	opacity: 0.5;
	cursor: not-allowed;
}

.report-bug-form button:active {
	background: #ac7f51;
}

.report-bug-form .required { 
	font-weight: bold;
	color: #e11;
}

.response-message-success {
	color: #2d2;
}

.response-message-error {
	color: #e11;
}