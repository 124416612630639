.relics-page {
    width: 80%;
    background-color: rgba(14, 14, 14, 0.8);
    margin-top: 70px;
    padding: 20px;
    margin-bottom: 70px;
    border-radius: 5px;
    color: white;
}
.relics-page h1 {
    font-size: 2.25rem;
    font-weight: 600;
}

.relic-list { display: flex; align-items: stretch; justify-content: center; gap: 24px; list-style: none; padding: 0; flex-wrap: wrap }

.relics-filter { margin: 24px 0; display: flex; flex-wrap: wrap; align-items: center; justify-content: center; gap: 24px }
.relics-filter-group { display: flex; flex-wrap: wrap; align-items: center; justify-content: center; gap: 8px }
.relics-filter-group-title { font-weight: bold; color: #bbb }
.relics-filter-group > ul { margin: 0; list-style: none; padding: 0; display: flex; flex-wrap: wrap; align-items: stretch; justify-content: center; gap: 8px }
.relics-filter-group > ul > li { border: 2px solid rgba(255,255,255,0.4); padding: 6px 12px; font-weight: 500; border-radius: 12px; cursor: pointer }
.relics-filter-group > ul > li:hover { background: rgba(255,255,255,0.2) }
.relics-filter-group > ul > li.selected { border-color: #b48a5f; background: #b48a5f }

.relic { background: #b48a5f; border-radius: 6px; padding: 6px; height: 100%; box-sizing: border-box; width: 450px; gap: 6px; display: flex; flex-direction: column }
.relic-header { display: flex; align-items: center }
.relic-main { background: #c7b492; border-radius: 4px; color: #534032; flex-grow: 1; font-weight: 500; padding: 0 18px }
.relic-main strong { font-weight: 900 }
.relic-icon { display: block; border: 1px solid #000; border-radius: 3px; width: 90px; height: 60px }
.relic-title { text-transform: uppercase; padding: 0 12px; text-align: left; letter-spacing: -1px; font-size: 24px; line-height: 30px; margin: 0; text-shadow: 1px 1px 0 #000, -1px -1px 0 #000, -1px 1px 0 #000, 1px -1px 0 #000, 1px 0 #000, -1px 0 #000, 0 1px #000, 0 -1px #000, 1px 2px 0 #000; font-weight: 900 }

.relic-ability { margin: 18px 0; color: #906a43; text-transform: uppercase }
.relic-ability:after { content: ''; display: block; border-bottom: 1px solid #b48a5f; margin: 18px 0 }
.relic-ability > ul { list-style: none; margin: 0; padding: 0 }
.relic-ability > ul > li { display: flex; align-items: center; gap: 6px }
.relic-ability .key:after { content: ':' }

.relic-values { margin: 18px 0 }
.relic-values > ul { list-style: none; margin: 0; padding: 0 }
.relic-values > ul > li { display: flex; justify-content: flex-start; align-items: center; gap: 6px; flex-wrap: nowrap; text-align: left; margin: 4.5px 0 }
.relic-values > ul > li .key { color: #906a43; white-space: nowrap }
.relic-values > ul > li .key:after { content: ':' }
.relic-values > ul > li .value { flex-grow: 1; text-overflow: ellipsis; overflow: hidden }
.relic-values > ul > li:hover .key { text-overflow: ellipsis; overflow: hidden }
.relic-values > ul > li:hover .value { text-overflow: unset; overflow: unset }

.relic-description { text-align: left }
.relic-upgrades { margin: 18px 0 }
.relic-upgrades:after { display: block; content: ''; border-top: 1px solid #b48a5f; margin: 18px 0 }
.relic-upgrades:before { display: block; content: ''; border-top: 1px solid #b48a5f; margin: 18px 0 }
.relic-upgrade { margin: 9px 0; text-align: left }
.relic-upgrade-type { font-weight: bold; text-transform: uppercase }

.relic-stats { display: flex; align-items: center; gap: 18px; margin: 18px 0; text-align: left }
.relic-stats-value { display: flex; align-items: normal; gap: 4.5px }
.relic-stats-value img { max-width: 24px }
.relic-stats-value .game-value:before { display: none }

.game-value { font-weight: bold; white-space: nowrap }
.game-value:before { background-color: currentColor; mask-position: center center; mask-size: contain; mask-repeat: no-repeat; display: inline-block; margin: 0 3px 2px 0; width: 16px; height: 16px; vertical-align: middle }
.game-value-time:before { content: ''; mask-image: url('icons/legend/time.svg') }
.game-value-radius:before { content: ''; mask-image: url('icons/legend/radius.svg') }
.game-value-damage:before { content: ''; mask-image: url('icons/legend/damage.svg') }